/**
 * Page for the conclusion of the pre-screener.
 */

import React from 'react';
import { Link, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import Footer from '../components/FooterSimple';
import Safe from '../components/Safe';
import { makeTopLinks } from '../util/pagetools';
import logo from '../images/pfizer-logo.png';
import { localizer } from '../util/localizer';

export default function ThankYou(props) {
  const ctx = props.pageContext;
  const localized = localizer(ctx);
  const backHome = localized('/');
  const cfg = (props.location ? (props.location.state || {}) : {});
  const isBrowser = typeof window !== 'undefined';

  // Redirect to home page if page is accessed without proper parameters
  if (Object.keys(cfg).length===0) {
    if (isBrowser) navigate(backHome);
    return null;
  }
  const rootStyle = (cfg.bg ? `thank-you bg-${cfg.bg}` : 'thank-you');
  const sitedata = cfg.sitedata;
  const toplinks = makeTopLinks(sitedata);
  const footdata = {
    ...sitedata.footer,
    toplinks,
    sitename: sitedata.sitename
  };
  if (isBrowser && window.threewireConsent) pageEvents(cfg.isQualified);
  const adsrvrImgTracker = <img height="1" width="1" style={{borderStyle:'none'}} alt="" src="https://insight.adsrvr.org/track/pxl/?adv=22vgjwc&ct=0:hiuu94w&fmt=3" />;

  return (
    <div id="page">
      <Helmet htmlAttributes={{lang: ctx.locale}}>
        <meta charSet="utf-8" />
        <title>{`Thank You | ${sitedata.sitename}`}</title>
        <link rel="canonical" href={`${sitedata.siteurl}/thankyou/`} />
        <meta name="description" content={cfg.header}></meta>
      </Helmet>
      <div className="layout-root">
        <div className={rootStyle}>
          <div id="logo">
            <Link to={backHome}>
              <img src={logo} alt={sitedata.sitename} className="img-responsive" />
            </Link>
          </div>
          <h1 className="thank-you-h1 text-yellow"><Safe type="span" content={cfg.header} /></h1>
          <h2 className="thank-you-h2"><Safe type="span" content={cfg.subheader} /></h2>
          {cfg.text &&
            <h2 className="thank-you-h2"><Safe type="span" content={cfg.text} /></h2>
          }
          <div className="thank-you-btn">
            <Safe to={backHome} className="slide-btn" type="link" content={sitedata.homelink} />
          </div>
        </div>
        <Footer src={footdata} localizerfn={localized} />
      </div>
      {window.threewireConsent && adsrvrImgTracker}
    </div>
  );
};

// Run tracking tag features
function pageEvents(isQualified) {
  if (window.gtag) {
    window.gtag('event', 'conversion', { 'send_to': 'AW-10945197917/9ij-COSq98wDEN3uieMo' });
    window.gtag('event', 'form_submitted', { 'event_category': 'Screener', 'event_label': `Form submitted ${(isQualified ? 'Q' : 'DQ')}` });
  }
}
